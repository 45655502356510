<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-card class="header-page-appjogo">
          <b-row align-v="center">
            <b-col sm="auto">
              <div class="icone-header-page">
                <img src="/assets/images/modal_icons/5_aplicação do jogo.png" />
              </div>
            </b-col>

            <b-col md="col">
              <h1 class="titulo-header-page text-white">
                Nova Aplicação de Jogo
                {{ this.gameId == 3 ? 'Teste' : 'Knapdown' }}
              </h1>
              <!-- <h4 class="text-white">Inicie uma Aplicação de Jogo:</h4> -->
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col v-if="!initiated">
        <b-card>
          <b-col md="12">
            <b-card-group class="w-100">
              <b-card
                title="Inicie a Aplicação de um Jogo"
                class="position-static w-100"
              >
                <b-card-text>
                  Lorem ipsum dolor sit amet. Ex repellat eos impedit atque ea
                  vitae fugit rem illum ipsa et voluptatibus excepturi non
                  soluta Quis! Ea molestias nulla est dolor quidem qui expedita
                  minus ab optio omnis ut velit nihil.
                </b-card-text>

                <b-button size="lg" variant="primary" @click="startGame()">
                  Começar!
                </b-button>

                <b-card-text> </b-card-text>
              </b-card>
            </b-card-group>
          </b-col>
        </b-card>
      </b-col>

      <b-col v-if="initiated">
        <Unity :unity="unityContext" width="960" height="600" ref="unity" />
      </b-col>
    </b-row>

    <!-- <b-button
      class="float-right mb-5"
      size="lg"
      variant="primary"
      @click="saveSession()"
      v-if="initiated"
    >
      Salvar Sessão
    </b-button> -->
    <!-- <b-button
      class="float-right mb-5"
      size="lg"
      variant="primary"
      @click="endGame()"
      v-if="initiated"
    >
      Finalizar Aplicação de Jogo
    </b-button> -->
    <b-button
      class="float-right mb-5 mr-1"
      size="lg"
      variant="primary"
      @click="maximizar()"
      v-if="initiated"
    >
      Maximizar Janela
    </b-button>
  </div>
</template>


<script>
import {
  BCard,
  BCardGroup,
  BCardText,
  BRow,
  BCol,
  BButton,
} from 'bootstrap-vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { required, email } from '@validations'
// import { codeIcon } from './code'
import * as moment from 'moment'
import UnityWebgl from 'unity-webgl'
import { JOGO_TESTE_ID } from '@/services/medicalAppointmentService'
import gameSessionService from '@/services/gameSessionService'
import gameLevelService from '@/services/gameLevelService'
import gameLevelSettingsService from '@/services/gameLevelSettingsService'
import gameAnalyticsEventService from '@/services/gameAnalyticsEventService'
import ToastificationContentVue from '@core/components/toastification/ToastificationContent.vue'

const Unity = new UnityWebgl({
  loaderUrl: '/assets/breakout/buildweb.loader.js',
  dataUrl: '/assets/breakout/buildweb.data',
  frameworkUrl: '/assets/breakout/buildweb.framework.js',
  codeUrl: '/assets/breakout/buildweb.wasm',
})

export default {
  components: {
    BCard,
    BCardGroup,
    BRow,
    BCol,
    BCardText,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    Unity: UnityWebgl.vueComponent,
  },
  props: ['patientId', 'gameId', 'medicalAppointmentId'],
  data() {
    return {
      unityContext: Unity,
      initiated: false,
      oldLevels: [],
    }
  },
  computed: {
    getSessions() {
      return window.sessions
    },
    getLevel() {
      return window.levels
    },
    getLevelSettings() {
      return window.levelSettings
    },
    getAnalyticsEvents() {
      return window.analyticsEvents
    },
  },
  methods: {
    maximizar() {
      this.unityContext.setFullscreen(0)
      this.unityContext.setFullscreen(1)
    },
    endGame() {
      console.log(this.getSessions)
      console.log(this.getLevel)
      console.log(this.getLevelSettings)
      console.log(this.getAnalyticsEvents)
    },
    async saveSession(oldSession) {
      if (!this.getLevelSettings.length) {
        console.log('Não há nenhum nível configurado')
        return
      }

      const session = oldSession || this.getSessions[0]
      const preparedSession = {
        patient: { id: this.patientId },
        game: { id: this.gameId },
        wasConcluded: session.wasConcluded,
      }
      // if (session.id) preparedSession['id'] = session.id
     preparedSession['id'] = null
      const { id: sessionId } = await gameSessionService[
        preparedSession['id'] ? 'update' : 'create'
      ](preparedSession)
      if (sessionId) preparedSession['id'] = sessionId

      const levels = this.getLevel.filter(
        level => level.sessionUID === session.UID
      )
      levels.forEach(async (level, index) => {
        // const method = level.id ? 'update' : 'create'
        const method = 'create'
        if (level.UID == '00000000-0000-0000-0000-000000000000') {
          return
        }

        // Salva o LevelSettings
        const levelSettingsFinded = this.getLevelSettings.find(
          levelSettings => levelSettings.UID === level.levelSettingsUID
        )
        levelSettingsFinded['id'] = null
        const { id: gameLevelSettingsId } = await gameLevelSettingsService[
          method
        ](levelSettingsFinded)
        if (method === 'create') levelSettingsFinded['id'] = gameLevelSettingsId

        // const sessionFinded = session || this.getSessions.find(
        //   sessionTarget => sessionTarget.UID === level.sessionUID
        // )
        const preparedLevel = {
          ...level,
          id: null,
          medicalAppointmentId: this.medicalAppointmentId,
          levelSettings: { id: levelSettingsFinded.id },
          session: { id: preparedSession['id'] },
          game: { id: this.gameId },
        }
        const { id: gameLevelId } = await gameLevelService[method](
          preparedLevel
        )
        if (method == 'create') level['id'] = gameLevelId
        await gameAnalyticsEventService.save(this.getPreparedAnalytics(level))
      })

      await this.deleteLevels()
      this.$toast.success({
        component: ToastificationContentVue,
        props: {
          title: 'Sessão salva com sucesso!',
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
      this.$router.push('/consulta/' + this.medicalAppointmentId)
    },
    startGame() {
      this.initiated = true
    },
    async getOldLevels() {
      // Recupera as Sessions
      const sessions = await gameSessionService.getByPatientAndGame(
        this.patientId,
        this.gameId
      )
      if (sessions.length) {
        sessions.forEach(session => {
          session.UID = session.id
        })
        const session = sessions[0]
        const d = new Date(
          new Date(session.updatedAt).getTime() - 1000 * 60 * 60 * 3
        )
        session.date = moment(session.updatedAt)
          .subtract(3, 'hours')
          .format('YYYY-MM-DD[T]HH:mm:ss')
        window.sessions = [session]

        // Recupera os Levels e LevelSettings
        const gameLevels = await gameLevelService.getBySession(session.id)
        window.levelSettings = []
        gameLevels.forEach(gameLevel => {
          gameLevel.UID = gameLevel.id
          gameLevel.levelSettingsUID = gameLevel.levelSettings.id
          gameLevel.levelSettings.UID = gameLevel.levelSettings.id
          gameLevel.sessionUID = gameLevel.session.id
          window.levelSettings.push(gameLevel.levelSettings)
        })

        this.oldLevels = structuredClone(gameLevels)
        window.levels = gameLevels
      }
    },

    getPreparedAnalytics(level) {
      const levelAnalytics = this.getAnalyticsEvents.filter(
        analytic => JSON.parse(analytic.data)['levelUID'] == level.UID
      )
      const levelInit = levelAnalytics.find(
        analytic => analytic.type == 'LevelStartEvent'
      )
      const levelEnd = levelAnalytics.find(
        analytic => analytic.type == 'LevelEndEvent'
      )
      const analyticsInLevel = this.getAnalyticsEvents.filter(
        analytic =>
          new Date(analytic.timestamp).getTime() >=
            new Date(levelInit.timestamp).getTime() &&
          new Date(analytic.timestamp).getTime() <=
            new Date(levelEnd.timestamp).getTime()
      )
      const countAnalytcsByType = type => {
        return analyticsInLevel.filter(analytic => analytic.type === type).length
      }
      return {
        allBallsLost: countAnalytcsByType('AllBallsLostEvent'),
        allBricksDestroyed: countAnalytcsByType('AllBricksDestroyedEvent'),
        ballCatch: countAnalytcsByType('BallCatchEvent'),
        ballLost: countAnalytcsByType('BallLostEvent'),
        ballRebound: countAnalytcsByType('BallReboundEvent'),
        brickBallHit: countAnalytcsByType('BrickBallHitEvent'),
        brickDestroyed: countAnalytcsByType('BrickDestroyedEvent'),
        brickExplosionHit: countAnalytcsByType('BrickExplosionHitEvent'),
        brickPistolHit: countAnalytcsByType('BrickPistolHitEvent'),
        bricksTotal: level.layout.filter(brick => brick > 0).length, 
        duration: Math.floor((new Date(levelEnd.timestamp) - new Date(levelInit.timestamp)) / 1000), 
        level: {
          id: level.id,
        },
        modifierCatch: countAnalytcsByType('ModifierCatchEvent'),
        modifierFound: countAnalytcsByType('ModifierFoundEvent'),
        pauses: countAnalytcsByType('PauseEvent'),
        pistolUsed: countAnalytcsByType('PistolUseEvent'),
      }
    },

    async deleteLevels() {
      const levelsToDelete = this.oldLevels.filter(
        level =>
          !this.getLevel.some(levelTarget => levelTarget.UID === level.UID)
      )
      levelsToDelete.forEach(async level => {
        await gameLevelService.delete(level.id)
      })
    },
  },
  async mounted() {
    window.isTest = this.gameId == JOGO_TESTE_ID
    await this.getOldLevels()
    window.onConcludedGame = oldSession => {
      console.log('onConcludedGame in vue')
      this.saveSession(oldSession)
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
