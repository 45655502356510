import axiosIns from '@/libs/axios'

const controller = 'game-analytics-event'

export default {
  async getById(id) {
    return axiosIns.get(`${controller}/${id}`).then(res => res.data)
  },
  async getByGameLevel(id) {
    return axiosIns.get(`${controller}/game-level/${id}`).then(res => res.data)
  },
  async save(obj) {
    return axiosIns.post(controller, obj).then(res => res.data)
  },
  async saveAll(req) {
    return axiosIns.post(`${controller}s`, req).then(res => res.data)
  },
}
