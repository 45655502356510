import axiosIns from '@/libs/axios'

const controller = 'game-level'

export default {
  async getAll() {
    return axiosIns.get(controller).then(res => res.data.content)
  },
  async getOldSessionsPacientGame(patientId, gameId) {
    return axiosIns.get(`${controller}?patientId=${patientId}&gameId=${gameId}`).then(res => res.data.content)
  },
  async getGameLevelByGameAndAppointment(gameId, appointmentId) {
    return axiosIns.get(`${controller}/game/${gameId}/medical-appointment/${appointmentId}`).then(res => res.data)
  },
  async getById(id) {
    return axiosIns.get(`${controller}/${id}`).then(res => res.data)
  },
  async getBySession(sessionId) {
    return axiosIns.get(`${controller}/session/${sessionId}`).then(res => res.data)
  },
  async update(obj) {
    return axiosIns.put(controller, obj).then(res => res.data)
  },
  async create(req) {
    return axiosIns.post(controller, req).then(res => res.data)
  },
  async delete(id) {
    return axiosIns.delete(`${controller}/${id}`).then(res => res.data)
  }
}
